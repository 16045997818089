import React from "react";

type CardData = {
  icon: React.ReactNode;
  text: string;
};

interface SectionalProps {
  data: CardData[];
}

const Sectional: React.FC<SectionalProps> = ({ data }) => {
  return (
    <div className="flex flex-col sm:flex-row sm:justify-center sm:items-center gap-6 sm:gap-6">
      {data.map((item, index) => (
        <React.Fragment key={index}>
          <div className="flex flex-col items-center text-center max-w-[180px] w-full">
            <div className="flex items-center justify-center w-12 h-12 bg-teal-100 rounded-full border border-teal-300">
              {item.icon}
            </div>
            <p className="mt-2 text-sm text-gray-700 break-words">
              {item.text}
            </p>
          </div>
          {index < data.length - 1 && (
            <div className="hidden sm:block h-12 border-l border-teal-300"></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Sectional;
