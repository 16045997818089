import CallToActionButton from "../buttons/CallToActionButton";

export default function HeroSectionOne() {
  return (
    <div
      className="w-full flex flex-col justify-center items-center text-center p-6 relative overflow-hidden rounded-md shadow-md md:p-8 lg:p-12"
      style={{
        background: `linear-gradient(120deg, rgba(34, 197, 94, 0.7), rgba(245, 245, 0, 0.7), rgba(99, 102, 241, 0.7))`,
      }}
    >
      <h1 className="text-4xl font-extrabold text-text max-w-full flex flex-wrap w-full justify-center items-center">
        <span>Automated sustainability compliance</span>
        <span className="pl-2 text-primary/90">supercharged by AI</span>
      </h1>
      <p className="mt-4 text-lg text-text-muted max-w-3xl sm:text-xl">
        Helping the fashion & retail industry measure and report their corporate
        and product impact using primary supply chain data
      </p>
      <br></br>
      <CallToActionButton className="shadow-md" />
      <br />

      {/* Centered Image */}
      <img
        src="/assets/dashboard.png"
        className="mt-6 rounded-lg border border-gray-300 shadow-lg w-full max-w-xs sm:max-w-md"
        alt="Dashboard screenshot"
      />
    </div>
  );
}
