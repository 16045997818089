import React, { useState, useEffect, useRef } from "react";
import CallToActionButton from "../buttons/CallToActionButton";

// Dummy images for the slider
const images = [
  "/assets/firstImage.jpg",
  "/assets/secondImage.jpg",
  "/assets/thirdImage.jpg",
  "/assets/fourthImage.jpg",
];

// Pagination button names
const paginationNames = ["Measure", "Report", "Transition"];

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const progressRefs = useRef(
    images.map(() => React.createRef<HTMLDivElement>()),
  );

  // Reset progress animation whenever currentIndex changes
  useEffect(() => {
    progressRefs.current.forEach((ref, index) => {
      if (ref.current) {
        ref.current.style.width = index === currentIndex ? "0%" : "100%";
        setTimeout(() => {
          if (ref.current && index === currentIndex) {
            ref.current.style.width = "100%";
          }
        }, 10);
      }
    });

    // fix: auto update timer when current index changes
    let intervalId: NodeJS.Timeout;
    intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);
    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [currentIndex]);

  // Function to handle manual left and right button clicks
  const handleLeftClick = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to handle pagination button clicks
  const handlePaginationClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative flex flex-col items-center space-y-4">
      <div className="flex flex-col md:flex-row items-center md:items-start w-full space-y-4 md:space-y-0 md:space-x-4">
        {/* Left Side - Pagination Buttons */}
        <div className="flex flex-col items-start space-y-2 w-full md:w-1/3">
          <h4 className="text-sm text-gray-500 mb-2 uppercase">
            Take control of your net-zero future
          </h4>
          {paginationNames.map((name, index) => (
            <button
              key={index}
              onClick={() => handlePaginationClick(index)}
              className={`text-3xl md:text-5xl font-semibold transition-all duration-2000 ${
                currentIndex === index || currentIndex === images.length - 1
                  ? "text-black"
                  : "text-gray-400"
              } hover:text-black focus:outline-none`}
            >
              {name}
              <span>.</span>
            </button>
          ))}
          <p className="text-gray-600 mt-4">
            Carbon accounting software for manufacturers, commodity traders, and
            their banks.
          </p>
          <CallToActionButton
            title="Schedule a call"
            className="bg-red-500 text-white px-6 py-2 mt-4 rounded"
          />
        </div>

        {/* Right Side - Image Slider */}
        <div className="relative flex justify-center items-center w-full md:w-2/3">
          <img
            src={images[currentIndex]}
            alt="Slider"
            className="aspect-[4/3] w-full h-full object-cover shadow-lg"
          />

          {/* Left and Right Buttons */}
          <button
            onClick={handleLeftClick}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-70 p-2 rounded-full"
          >
            <span className="text-2xl">&#10094;</span>
          </button>
          <button
            onClick={handleRightClick}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-70 p-2 rounded-full"
          >
            <span className="text-2xl">&#10095;</span>
          </button>

          {/* Progress Bars - Carousel Indicators */}
          <div className="absolute bottom-2 left-0 w-full flex justify-center space-x-1">
            {images.map((_, index) => (
              <div key={index} className="w-1/6 h-1 bg-gray-300">
                <div
                  ref={progressRefs.current[index]}
                  className={`h-full bg-green-500 transition-all duration-[5s] ${
                    currentIndex === index ? "" : "hidden"
                  }`}
                  style={{ width: currentIndex === index ? "100%" : "0%" }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
