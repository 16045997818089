import { ReactNode } from "react";

type AppContainerProps = {
  children?: ReactNode;
};

export default function AppContainer({ children }: AppContainerProps) {
  return (
    <div className="bg-background">
      {/* scrollbar */}
      <div className="overflow-y-scroll scrollbar-thin scrollbar-thumb-primary scrollbar-track-background h-screen">
        {/* main padding and spacing */}
        <div className="relative px-6 pt-4 lg:px-8">
          <div className="mx-auto max-w-5xl py-8 sm:py-16 lg:py-32 space-y-28">
            {/* main content goes here */}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
