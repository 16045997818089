import { TestimonialCardItem } from "../../../typedefs/Testimonial";

type TestimonialCardProps = {
  item: TestimonialCardItem;
};

export default function TestimonialLargeCard({ item }: TestimonialCardProps) {
  return (
    <div className="w-full max-w-2xl mx-auto my-8 p-8 bg-surface rounded-lg shadow-lg relative">
      {/* Top Left Quotation Mark */}
      <span className="absolute top-2 left-4 text-4xl text-red-500 font-bold">
        “
      </span>

      {/* Card content */}
      <div className="flex flex-col md:flex-row items-start h-full gap-6">
        {/* Left: Image */}
        <div className="w-full md:w-1/2">
          <img
            src={item.imageUrl}
            alt="User profile"
            className="rounded-lg w-full h-full object-cover"
          />
        </div>

        {/* Right: Text */}
        <div className="w-full md:w-1/2">
          <p className="text-lg font-medium text-gray-700">{item.text}</p>

          {/* Bottom Border for styling */}
          <div className="mt-6 border-t border-dotted border-gray-300 w-2/3"></div>

          {/* User credentials */}
          <div className="text-sm text-gray-600 mt-2 text-left">
            <p className="font-bold">{item.user.name}</p>
            <p>{item.user.designation}</p>
            <p>{item.user.organization}</p>
          </div>
        </div>
      </div>

      {/* Bottom Right Quotation Mark */}
      <span className="absolute bottom-2 right-4 text-4xl text-red-500 font-bold">
        ”
      </span>
    </div>
  );
}
