import React from "react";

const TrustedBy = () => {
  // Dummy logo images (replace these with your own paths)
  const logos = [
    { src: "/logo192.png", alt: "Microsoft for Startups" },
    { src: "/logo192.png", alt: "Accenture & IIMA Ventures" },
    { src: "/logo192.png", alt: "GFA Innovation Forum" },
    { src: "/logo192.png", alt: "Another Partner" },
    { src: "/logo192.png", alt: "Yet Another Partner" },
  ];

  return (
    <section className="flex flex-col items-center w-full p-8 bg-[--color-background]">
      {/* Title */}
      <h2 className="text-3xl font-semibold mb-6 text-center">Trusted by</h2>

      {/* Logos Grid Container */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-6 w-full">
        {logos.map((logo, index) => (
          <div key={index} className="flex items-center justify-center">
            <img
              src={logo.src}
              alt={logo.alt}
              className="h-16 md:h-20 object-contain grayscale hover:grayscale-0 transition-all duration-300"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default TrustedBy;
