import React from "react";

const BackedBy = () => {
  // Dummy logo images (replace these with your own paths)
  const logos = [
    { src: "/logo192.png", alt: "Microsoft for Startups" },
    { src: "/logo192.png", alt: "Accenture & IIMA Ventures" },
    { src: "/logo192.png", alt: "GFA Innovation Forum" },
  ];

  return (
    <section className="flex flex-col items-center w-full p-8 bg-[--color-background]">
      {/* Title */}
      <h2 className="text-4xl font-bold mb-4">Backed by</h2>

      {/* Logos Container */}
      <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 w-full items-center justify-between">
        {logos.map((logo, index) => (
          <div
            key={index}
            className="flex items-center justify-center w-full sm:w-64 h-32 bg-[--color-surface] rounded-xl shadow-lg p-4 transition-transform transform hover:scale-105"
          >
            <img src={logo.src} alt={logo.alt} className="max-h-full" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default BackedBy;
