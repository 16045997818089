import AppContainer from "./components/containers/AppContainer";
import FaqsSection from "./components/faqs/FaqsSection";
import { ThemeProvider } from "./hooks/ThemeContext";
import Footer from "./components/footer/Footer";
import LearnMoreSection from "./components/contact-us/LearnMoreSection";
import Navbar from "./components/navbar/NavBar";
import HeroSectionOne from "./components/sections/HeroSectionOne";
import ClimatePlatformHeroSection from "./components/sections/ClimateProductHeroSection";
import HeroSectionTwo from "./components/sections/HeroSectionTwo";
import BackedBy from "./components/sections/BackedBy";
import TrustedBy from "./components/sections/TrustedBySec";
import DummyDisplay from "./components/sections/TestimonialCardSection";
import ListCardSection from "./components/sections/ListCardSection";
import OverlaySection from "./components/sections/OverlaySec";
// import MeetFounders from "./components/sections/MeetFounders";

export default function App() {
  return (
    <ThemeProvider>
      <AppContainer>
        <Navbar />
        <HeroSectionOne />
        <HeroSectionTwo />
        <OverlaySection />
        <FaqsSection />
        <DummyDisplay />
        <LearnMoreSection />
        <ClimatePlatformHeroSection />
        {/* <MeetFounders /> */}
        <TrustedBy />
        <BackedBy />
        <ListCardSection />
        <Footer />
      </AppContainer>
    </ThemeProvider>
  );
}
