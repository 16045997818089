import { TestimonialCardItem } from "../../typedefs/Testimonial";
import TestimonialLargeCard from "./CardComponents/TestimonialLargeCard"; // Assuming you have the component in a separate file

const DummyTestimonial: TestimonialCardItem = {
  imageUrl: "/assets/secondImage.jpg",
  text: "I am passionate about sustainability and AI, driving innovation in the retail industry. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  user: {
    name: "John Doe",
    designation: "Sustainability Lead",
    organization: "EcoTech Innovations",
  },
} as const;

export default function TestimonalCardSection() {
  return (
    <div className="flex justify-center items-center h-screen">
      <TestimonialLargeCard item={DummyTestimonial} />
    </div>
  );
}
